// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import EmailIcon from '@mui/icons-material/Email'

// ** Type Imports
import { Model, User } from '@/types'
import type { PushUserParams } from '@/types/push'

// ** Component Imports
import SelectModal from '../../components/modal/select-modal'
import LanguageSelect from '../../components/languageSelect'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'email',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '아이디',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.email}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'status',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '상태',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {row.status === 'ACTIVE' && (
          <Typography variant="body2">활성화</Typography>
        )}
        {row.status === 'WAIT' && <Typography variant="body2">대기</Typography>}
        {row.status === 'INACTIVE' && (
          <Typography variant="body2">정지</Typography>
        )}
        {row.status === 'DELETE' && (
          <Typography variant="body2">탈퇴</Typography>
        )}
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'type',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '가입 형태',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.socialType}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'lang',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '언어',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.lang}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'os',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '플랫폼',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.os}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'model',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '모델',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.model}</Typography>
      </Box>
    ),
  },
]

interface Props {
  data: User[]
  page: number
  count: number
  query: PushUserParams
  selectedRows: number[]
  selectedRows2: number[]
  selectData: User[]
  setPage: any
  modelData: Model[]
  open: boolean
  cancelOpen: boolean
  push: {
    title: string
    text: string
  }
  page2: number
  setPage2: (e: number) => void
  handleInit: () => void
  handleCancelOpen: () => void
  handleCancelClose: () => void
  handleOpen: () => void
  handleClose: () => void
  handlePushMail: () => void
  setPush: (e: any) => void
  setQuery: (e: any) => void
  handleRefetch: () => void
  handleInitQuery: () => void
  setSelectedRows: (e: any) => void
  setSelectedRows2: (e: any) => void
  handleAddSelectUserList: () => void
  handleRemoveSelectUserList: () => void
  handleSelectAll: () => void
  handleUnSelectAll: () => void
}

const PushPageView = ({
  data,
  page,
  count,
  handleRefetch,
  setPage,
  query,
  setQuery,
  modelData,
  handleInitQuery,
  selectedRows,
  setSelectedRows,
  handleAddSelectUserList,
  selectData,
  selectedRows2,
  setSelectedRows2,
  handleRemoveSelectUserList,
  push,
  setPush,
  handlePushMail,
  handleClose,
  handleOpen,
  open,
  cancelOpen,
  handleCancelClose,
  handleCancelOpen,
  handleInit,
  page2,
  setPage2,
  handleSelectAll,
  handleUnSelectAll,
}: Props) => {
  const columns = [...defaultColumns]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Box sx={{ mb: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Typography variant="h5">사용자 조회</Typography>
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id="social">가입 형태</InputLabel>
                  <Select
                    labelId="social"
                    label="가입 형태"
                    value={query.socialType}
                    name="socialType"
                    onChange={setQuery}
                  >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="LOCAL">Local</MenuItem>
                    <MenuItem value="FACEBOOK">Facebook</MenuItem>
                    <MenuItem value="GOOGLE">Google</MenuItem>
                    <MenuItem value="APPLE">Apple</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <LanguageSelect value={query.lang} onChange={setQuery} />
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id="os">플랫폼</InputLabel>
                  <Select
                    labelId="os"
                    label="플랫폼"
                    value={query.osType}
                    name="osType"
                    onChange={setQuery}
                  >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="AOS">AOS</MenuItem>
                    <MenuItem value="iOS">iOS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id="model">모델</InputLabel>
                  <Select
                    labelId="model"
                    label="모델"
                    value={query.modelId}
                    name="modelId"
                    onChange={setQuery}
                  >
                    <MenuItem value={0}>전체</MenuItem>
                    {modelData.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  placeholder="검색할 정보를 입력하세요."
                  label="아이디 검색"
                  value={query.email}
                  name="email"
                  onChange={setQuery}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleRefetch}
                >
                  조회
                </Button>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  fullWidth
                  onClick={handleInitQuery}
                >
                  초기화
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button variant="contained" onClick={handleSelectAll}>
              사용자 전체 추가
            </Button>
            <DataGrid
              sx={{ mt: 2 }}
              columns={columns}
              rows={data}
              rowCount={count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={(row) => setSelectedRows(row)}
              selectionModel={selectedRows}
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={2}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={handleAddSelectUserList}
        >
          추가
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          color="error"
          onClick={handleRemoveSelectUserList}
        >
          제외
        </Button>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Box sx={{ mb: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Typography variant="h5">수신 대상자 조회</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={handleUnSelectAll}
            >
              사용자 전체 제외
            </Button>
            <DataGrid
              sx={{ mt: 2 }}
              columns={columns}
              rows={selectData}
              rowCount={selectData.length}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={(row) => setSelectedRows2(row)}
              selectionModel={selectedRows2}
              pagination
              page={page2}
              paginationMode="client"
              pageSize={10}
              onPageChange={(newPage) => setPage2(newPage)}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">
                <EmailIcon /> 푸시발송 내용
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="제목"
                value={push.title}
                onChange={setPush}
                name="title"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={30}
                label="내용"
                value={push.text}
                onChange={setPush}
                name="text"
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="error"
                onClick={handleCancelOpen}
              >
                취소
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={handleOpen}
              >
                발송
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Card>
      </Grid>
      {open && (
        <SelectModal
          state={open}
          closeEvent={handleClose}
          event={handlePushMail}
          message="해당 푸시를 발송하시겠습니까?"
          one="발송"
          two="닫기"
        />
      )}
      {cancelOpen && (
        <SelectModal
          state={cancelOpen}
          closeEvent={handleCancelClose}
          event={handleInit}
          message="취소 시 해당 푸시 내용이 저장되지 않습니다. 취소하시겠습니까?"
          one="취소"
          two="닫기"
        />
      )}
    </Grid>
  )
}

export default PushPageView
