// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'

// * Component Imports
import BarChart from '../../components/chart'
import DateRangePicker from '../../components/datepicker'
import LanguageSelect from '../../components/languageSelect'

// ** Type Imports
import { Dates, Model, UserChart } from '@/types'

// ** Utils Imports
import { getYearList } from '../../utils/date'

interface Props {
  day: number
  dates: Dates
  query: {
    type: string
    lang: string
    osType: string
    socialType: string
    modelId: string
  }
  modelData: Model[]
  data: UserChart[]
  year: number
  handleYear: (e: SelectChangeEvent<number>) => void
  handleExcelDownload: () => void
  handleInitQuery: () => void
  handleRefetch: () => void
  setQuery: (e: any) => void
  setDates: (e: any) => void
  handleDay: (e: SelectChangeEvent<number>) => void
}

const UserChartPageView = ({
  day,
  handleDay,
  dates,
  setDates,
  query,
  setQuery,
  handleRefetch,
  modelData,
  data,
  handleInitQuery,
  handleExcelDownload,
  year,
  handleYear,
}: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="status">상태</InputLabel>
                <Select
                  labelId="status"
                  label="상태"
                  value={query.type}
                  name="type"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="ACTIVE">활성화</MenuItem>
                  <MenuItem value="WAIT">대기</MenuItem>
                  <MenuItem value="DELETE">탈퇴</MenuItem>
                  <MenuItem value="INACTIVE">정지</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="social">가입 형태</InputLabel>
                <Select
                  labelId="social"
                  label="가입 형태"
                  value={query.socialType}
                  name="socialType"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="LOCAL">Local</MenuItem>
                  <MenuItem value="FACEBOOK">Facebook</MenuItem>
                  <MenuItem value="GOOGLE">Google</MenuItem>
                  <MenuItem value="APPLE">Apple</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <LanguageSelect value={query.lang} onChange={setQuery} />
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="os">플랫폼</InputLabel>
                <Select
                  labelId="os"
                  label="플랫폼"
                  value={query.osType}
                  name="osType"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="AOS">AOS</MenuItem>
                  <MenuItem value="iOS">iOS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="model">모델</InputLabel>
                <Select
                  labelId="model"
                  label="모델"
                  value={query.modelId}
                  name="modelId"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  {modelData.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRefetch}
              >
                조회
              </Button>
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                color="error"
                size="large"
                fullWidth
                onClick={handleInitQuery}
              >
                초기화
              </Button>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="date">조회 단위</InputLabel>
                <Select
                  labelId="date"
                  label="조회 단위"
                  value={day || 1}
                  onChange={handleDay}
                >
                  <MenuItem value={1}>일일</MenuItem>
                  <MenuItem value={7}>1주일</MenuItem>
                  <MenuItem value={30}>1개월</MenuItem>
                  <MenuItem value={90}>3개월</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {day !== 30 && day !== 90 ? (
                <DateRangePicker dates={dates} setDates={setDates} />
              ) : (
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="date">연도</InputLabel>
                  <Select
                    labelId="date"
                    label="연도"
                    value={year}
                    onChange={handleYear}
                  >
                    {getYearList().map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              sx={{ mb: 2, ml: 10 }}
              color="success"
              onClick={handleExcelDownload}
            >
              엑셀 다운로드
            </Button>
          </Box>
          <BarChart data={data} />
        </Card>
      </Grid>
    </Grid>
  )
}

export default UserChartPageView
