// ** COmponent Imports
import SelectModal from '../../../components/modal/select-modal'
import SimpleUploader from '../../../components/simpleUploader'

// ** Mui Imports
import {
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
} from '@mui/material'

// ** Type Imports
import { Model } from '../../../types'
import type { ManualParams } from '@/types/manual'

interface Props {
  state: boolean
  isNew: boolean
  query: ManualParams
  setQuery: any
  modelData: Model[]
  open: boolean
  editOpen: boolean
  regContent: () => void
  modContent: () => void
  setFilePath: (filePath: string) => void
  closeEvent: () => void
  setFileName: (fileName: string) => void
  handleOpen: () => void
  handleClose: () => void
  handleEditOpen: () => void
  handleEditClose: () => void
}

const UserManualModalView = ({
  state,
  closeEvent,
  isNew,
  query,
  setQuery,
  setFilePath,
  regContent,
  modelData,
  modContent,
  setFileName,
  handleClose,
  handleOpen,
  handleEditClose,
  handleEditOpen,
  editOpen,
  open,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>User Manual {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model">모델</InputLabel>
            <Select
              labelId="model"
              label="모델"
              defaultValue=""
              value={String(query.modelId)}
              name="modelId"
              onChange={setQuery}
            >
              <MenuItem value="">전체</MenuItem>
              {modelData.map((item: Model) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="URL를 입력해주세요."
            label="링크"
            onChange={setQuery}
            value={query.link}
            name="link"
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={isNew ? handleOpen : handleEditOpen}
          >
            저장
          </Button>
        </Grid>
        <Grid item xs={4} />
        {open && (
          <SelectModal
            state={open}
            closeEvent={handleClose}
            event={regContent}
            message="정말 저장하시겠습니까?"
            one="저장"
            two="닫기"
          />
        )}
        {editOpen && (
          <SelectModal
            state={editOpen}
            closeEvent={handleEditClose}
            event={modContent}
            message="정말 수정하시겠습니까?"
            one="수정"
            two="닫기"
          />
        )}
      </Grid>
    </Dialog>
  )
}

export default UserManualModalView
