// ** React Imports
import { useEffect, useState } from 'react'

// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Grid,
  Tab,
  Tabs,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Type Imports
import { Faq, Model, UserManual, VideoGuide } from '@/types'

//** Component Imports
import UserManualModal from './user-manual-modal'
import VideoGuidelModal from './video-guide-modal'
import FaqModal from './faq-modal'
import SelectModal from '../../components/modal/select-modal'

// ** Util Imports
import dayjs from 'dayjs'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const defaultColumns1: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: UserManual }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'model',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '모델',
    renderCell: ({ row }: { row: UserManual }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.model.name}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'filename',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '주소',
    renderCell: ({ row }: { row: UserManual }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.link}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: UserManual }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

const defaultColumns2: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: VideoGuide }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'model',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '모델',
    renderCell: ({ row }: { row: VideoGuide }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.model.name}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'filename',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '주소',
    renderCell: ({ row }: { row: VideoGuide }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.link}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: VideoGuide }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

const defaultColumns3: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: Faq }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'model',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '모델명',
    renderCell: ({ row }: { row: Faq }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.model.name}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'title',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '제목',
    renderCell: ({ row }: { row: Faq }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.title}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'status',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '상태',
    renderCell: ({ row }: { row: Faq }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {row.exposeYn === 'Y' ? '공개중' : '비공개중'}
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: Faq }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data1: { data: UserManual[]; count: number }
  data2: { data: VideoGuide[]; count: number }
  data3: { data: Faq[]; count: number }
  modelData: Model[]
  delOpen: boolean
  menualOpen: boolean
  videoOpen: boolean
  faqOpen: boolean
  isNew: boolean
  page: number
  query: any
  videoId: number
  faqId: number
  manualId: number
  setPage: any
  selectRows: number[]
  handleMenualOpen: (state: boolean, manualId?: number) => void
  handleMenualClose: () => void
  handleVideoOpen: (state: boolean, videoId?: number) => void
  handleVideoClose: () => void
  handleFaqOpen: (state: boolean, faqId?: number) => void
  handleFaqClose: () => void
  videoRefetch: () => void
  handleDeleteVideo: () => void
  setSelectedRows: any
  setQuery: any
  faqRefetch: () => void
  handleDeleteFaq: () => void
  manualRefetch: () => void
  handleDeleteManual: () => void
  handleDelOpen: () => void
  handleDelClose: () => void
}

const UserGuidePageView = ({
  data1,
  data2,
  data3,
  menualOpen,
  faqOpen,
  videoOpen,
  handleFaqClose,
  handleFaqOpen,
  handleMenualClose,
  handleMenualOpen,
  handleVideoClose,
  handleVideoOpen,
  isNew,
  page,
  setPage,
  modelData,
  query,
  setQuery,
  videoId,
  videoRefetch,
  selectRows,
  setSelectedRows,
  handleDeleteVideo,
  faqRefetch,
  faqId,
  handleDeleteFaq,
  manualRefetch,
  manualId,
  handleDeleteManual,
  delOpen,
  handleDelClose,
  handleDelOpen,
}: Props) => {
  const [value, setValue] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const columns1: any = [
    ...defaultColumns1,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: UserManual }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleMenualOpen(false, row.id)}>수정</Button>
        </Box>
      ),
    },
  ]
  const columns2: any = [
    ...defaultColumns2,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: VideoGuide }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleVideoOpen(false, row.id)}>수정</Button>
        </Box>
      ),
    },
  ]
  const columns3: any = [
    ...defaultColumns3,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: Faq }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleFaqOpen(false, row.id)}>수정</Button>
        </Box>
      ),
    },
  ]

  useEffect(() => {
    setSelectedRows([])
  }, [value, page])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="User Manual" {...a11yProps(0)} />
            <Tab label="Video Guide" {...a11yProps(1)} />
            <Tab label="FAQ" {...a11yProps(2)} />
          </Tabs>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <TabPanel value={value} index={0}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <Typography variant="h4">User Manual 리스트</Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleMenualOpen(true)}
                >
                  등록
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleDelOpen}
                >
                  선택 삭제
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              columns={columns1}
              rows={data1.data}
              rowCount={data1.count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={(row) => setSelectedRows(row)}
              selectionModel={selectRows}
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <Typography variant="h4">Video Guide 리스트</Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleVideoOpen(true)}
                >
                  등록
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleDelOpen}
                >
                  선택 삭제
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              columns={columns2}
              rows={data2.data}
              rowCount={data2.count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={(row) => setSelectedRows(row)}
              selectionModel={selectRows}
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={3}>
                <Typography variant="h4">FAQ 리스트</Typography>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="model">모델</InputLabel>
                  <Select
                    labelId="model"
                    label="모델"
                    defaultValue=""
                    value={query.modelId}
                    name="modelId"
                    onChange={setQuery}
                  >
                    <MenuItem value="">전체</MenuItem>
                    {modelData.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleFaqOpen(true)}
                >
                  등록
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleDelOpen}
                >
                  선택 삭제
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              columns={columns3}
              rows={data3.data}
              rowCount={data3.count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={(row) => setSelectedRows(row)}
              selectionModel={selectRows}
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </TabPanel>
        </Card>
      </Grid>
      {menualOpen && (
        <UserManualModal
          state={menualOpen}
          closeEvent={handleMenualClose}
          isNew={isNew}
          manualId={manualId}
          manualRefetch={manualRefetch}
        />
      )}
      {videoOpen && (
        <VideoGuidelModal
          state={videoOpen}
          closeEvent={handleVideoClose}
          isNew={isNew}
          videoId={videoId}
          videoRefetch={videoRefetch}
        />
      )}
      {faqOpen && (
        <FaqModal
          state={faqOpen}
          closeEvent={handleFaqClose}
          isNew={isNew}
          faqRefetch={faqRefetch}
          faqId={faqId}
        />
      )}
      {delOpen && (
        <SelectModal
          state={delOpen}
          closeEvent={handleDelClose}
          event={() => {
            switch (value) {
              case 0:
                return handleDeleteManual()

              case 1:
                return handleDeleteVideo()

              case 2:
                return handleDeleteFaq()

              default:
                break
            }
          }}
          message="정말로 삭제하시겠습니까?"
          one="확인"
          two="닫기"
        />
      )}
    </Grid>
  )
}

export default UserGuidePageView
