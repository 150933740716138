import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'

interface LanguageSelectProps {
  value: string
  onChange: (e: any) => void
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ value, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="lang-label">언어</InputLabel>
      <Select
        labelId="lang-label"
        label="언어"
        name="lang"
        value={value}
        onChange={onChange}
      >
        <MenuItem value="">전체</MenuItem>
        <MenuItem value="en">en</MenuItem>
        <MenuItem value="fr">fr</MenuItem>
        <MenuItem value="de">de</MenuItem>
        <MenuItem value="es">es</MenuItem>
        <MenuItem value="lt">lt</MenuItem>
        <MenuItem value="tr">tr</MenuItem>
        <MenuItem value="pt">pt</MenuItem>
      </Select>
    </FormControl>
  )
}

export default LanguageSelect
